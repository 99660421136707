import Card from 'components/card';
import InputField from 'components/fields/InputField';
import api from '../../../../api';
import Swal from 'sweetalert2';
import { Spinner } from '@chakra-ui/spinner';
import React, { useState } from 'react';
import { useAuth } from 'contexts/AuthContext';

const Password = () => {
  const auth = useAuth();
  const [buttonloading, setButtonLoading] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    setButtonLoading(true);
    const form = new FormData(e.target);
    const password = form.get('password');
    const confirm_password = form.get('confirm_password');
    const id = auth.user.id;
    if (password === confirm_password) {
      api
        .post('/account/set_password', { id, password })
        .then(({ data }) => {
          setButtonLoading(false);
          if (data.status === 'success') {
            Swal.fire({
              title: 'Success!',
              text: `Password have successfully been changed, please login with your new password.`,
              icon: 'success',
              confirmButtonText: 'OK',
            }).then(() => {
              auth.logout();
              window.location.replace('/auth/login');
            });
          } else if (data.status === 'error') {
            Swal.fire({
              title: 'Error!',
              text: `Setting password was not successful! Error: ${data.message}`,
              icon: 'error',
              confirmButtonText: 'OK',
            });
          } else {
            Swal.fire({
              title: 'Error!',
              text: `Setting password was not successful!`,
              icon: 'error',
              confirmButtonText: 'OK',
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setButtonLoading(false);
      Swal.fire({
        title: 'Error!',
        text: `Confirm password is different from password!`,
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };
  return (
    <Card extra={'w-full p-4'}>
      {/* Header */}
      <div className="w-full px-2">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Change Password
        </h4>
        <p className="mt-1 text-base text-gray-600">
          Here you can set your new password
        </p>
      </div>
      {/* inputs */}
      <form onSubmit={handleSubmit}>
        <div className="mt-7 grid grid-cols-1 gap-3">
          <InputField
            field_name="password"
            label="New Password"
            placeholder="Your New Password"
            id="password"
            type="password"
          />
          <InputField
            field_name="confirm_password"
            label="New Password Confirmation"
            placeholder="Confirm New Password"
            id="confirm_password"
            type="password"
          />
          <div className="mt-12 flex w-full justify-end">
            {buttonloading ? (
              <button
                className="linear rounded-md bg-brand-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                disabled="true"
              >
                <Spinner
                  size="xl"
                  thickness="2px"
                  style={{ height: 14, width: 14 }}
                />
              </button>
            ) : (
              <button className="linear rounded-md bg-brand-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                Change Password
              </button>
            )}
          </div>
        </div>
      </form>
    </Card>
  );
};

export default Password;
