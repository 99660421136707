import Card from 'components/card';
import { useAuth } from 'contexts/AuthContext';
import md5 from 'blueimp-md5';

const Profile = () => {
  const auth = useAuth();
  const user = auth.user;
  function hash(email) {
    const lowerCaseEmail = email.toLowerCase().trim();
    const md5Hash = md5(lowerCaseEmail);
    return md5Hash;
  }
  function get_pfp(email) {
    return `https://www.gravatar.com/avatar/${hash(email)}`;
  }
  return (
    <Card extra={'w-full py-[18px] px-[18px] h-[140px]'}>
      <div className="flex items-center gap-3">
        <div className="flex h-[90px] w-[90px] items-center justify-center rounded-md">
          <img
            className="h-full w-full rounded-full"
            src={get_pfp(auth.user.email)}
            alt={
              'Profile Picture of' +
              auth.user.firstname +
              ' ' +
              auth.user.lastname
            }
          />
        </div>
        <div>
          <p className="text-xl font-bold text-navy-700 dark:text-white">
            {user.firstname + ' ' + user.lastname}
          </p>
          <p className="text-base text-gray-600">{user.email}</p>
        </div>
      </div>
    </Card>
  );
};

export default Profile;
