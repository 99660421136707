import React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import routes from 'routes';
import { Spinner } from '@chakra-ui/spinner';
import { useAuth } from 'contexts/AuthContext';

export default function Auth() {
  const auth = useAuth();
  const location = useLocation();
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === '/auth') {
        return (
          <Route path={`${prop.path}`} element={prop.component} key={key} />
        );
      }
      if (prop.collapse) {
        return getRoutes(prop.items);
      }
      return null;
    });
  };

  if (auth.loading) {
    return (
      <div
        style={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spinner
          size="xl"
          thickness="6px"
          style={{ height: 200, width: 200 }}
        />
      </div>
    );
  }
  if (auth.token && location.pathname !== '/auth/2fa') {
    if (auth.token && !auth.two_factor) {
      return <Navigate to="/auth/2fa" replace />;
    }
  }

  if (auth.token && auth.two_factor) {
    return <Navigate to="/dashboard" replace />;
  }

  document.documentElement.dir = 'ltr';

  return (
    <div>
      <div className="relative float-right h-full min-h-screen w-full dark:!bg-navy-900">
        <main className={`mx-auto min-h-screen`}>
          <Routes>
            {getRoutes(routes)}
            <Route path="/" element={<Navigate to="/auth/login" replace />} />
          </Routes>
        </main>
      </div>
    </div>
  );
}
