import { MdHome } from 'react-icons/md';
import { FaCog } from 'react-icons/fa';

// Admin Imports
import Dashboard from 'views/dashboard';
import Signup from 'views/auth/SignUp.jsx';
import Login from 'views/auth/Login.jsx';
import NotFound from 'views/404';
import Setup from 'views/setup';
import ResetPassword from 'views/auth/ResetPassword.jsx';
import SetPassword from 'views/auth/SetPassword.jsx';
import TwoFactor from 'views/auth/2FA.jsx';
import Settings from 'views/account/settings';

const routes = [
  {
    name: 'Dashboard',
    layout: '/admin',
    path: 'dashboard',
    icon: <MdHome className="h-6 w-6" />,
    component: <Dashboard />,
  },
  {
    name: 'Settings',
    layout: '/admin',
    path: 'settings',
    icon: <FaCog className="h-6 w-6" />,
    component: <Settings />,
  },
  {
    name: 'Login',
    layout: '/auth',
    path: 'login',
    component: <Login />,
    sidebarLink: false,
  },
  {
    name: 'Reset Password',
    layout: '/auth',
    path: 'password/reset',
    component: <ResetPassword />,
    sidebarLink: false,
  },
  {
    name: 'Set Password',
    layout: '/auth',
    path: 'password/set',
    component: <SetPassword />,
    sidebarLink: false,
  },
  {
    name: 'Two Factor Verification',
    layout: '/auth',
    path: '2fa',
    component: <TwoFactor />,
    sidebarLink: false,
  },
  {
    name: 'Signup',
    layout: '/auth',
    path: 'signup',
    component: <Signup />,
    sidebarLink: false,
  },
  {
    name: '404',
    layout: '/admin',
    path: '404',
    component: <NotFound />,
    sidebarLink: false,
  },
  {
    name: 'Setup',
    layout: '/admin',
    path: 'setup',
    component: <Setup />,
    sidebarLink: false,
  },
];

export default routes;
