function Card(props) {
  const { variant, extra, children, ...rest } = props;
  getComputedStyle(document.documentElement).getPropertyValue(
    'var(--color-background-100)'
  );
  return (
    <div
      className={`relative flex flex-col rounded-[10px] border-[1px] border-gray-200 bg-white bg-clip-border shadow-md shadow-[#F3F3F3] dark:border-[#ffffff33] dark:!bg-navy-800 dark:text-white dark:shadow-none ${extra}`}
      {...rest}
    >
      {children}
    </div>
  );
}

export default Card;
