import Information from './components/Information';
import Password from './components/Password';
import Profile from './components/Profile';
import TwoFactor from './components/TwoFactor';

const Setting = () => {
  return (
    <div className="mt-3 grid h-full w-full grid-cols-1 gap-5 rounded-md lg:grid-cols-2">
      <div className="flex flex-col gap-5">
        <Profile />
        <Information />
      </div>

      <div className="flex flex-col gap-5">
        <TwoFactor />
        <Password />
      </div>
    </div>
  );
};

export default Setting;
