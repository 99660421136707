import React, { useState } from 'react';
import api from '../../api';
import { useAuth } from 'contexts/AuthContext';
import Swal from 'sweetalert2';
import InputField from 'components/fields/InputField';
import Default from 'layouts/auth/types/Default';
import Checkbox from 'components/checkbox';
import { Spinner } from '@chakra-ui/spinner';

function Signup() {
  useAuth();
  const [sbuttonloading, setSbuttonLoading] = React.useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = new FormData(e.target);
    const firstname = form.get('firstname');
    const lastname = form.get('lastname');
    const email = form.get('email');
    const password = form.get('password');
    const confirm_password = form.get('confirm_password');
    setSbuttonLoading(true);
    if (!isChecked) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Please accept the Terms of Service and Privacy Policy',
        confirmButtonText: 'OK',
      });
      setSbuttonLoading(false);
    } else {
      if (password !== confirm_password) {
        Swal.fire({
          title: 'Error!',
          text: `Passwords do not match!`,
          icon: 'error',
          confirmButtonText: 'OK',
        });
        setSbuttonLoading(false);
      } else {
        api
          .post('/auth/signup', {
            firstname,
            lastname,
            email,
            password,
          })
          .then(({ data }) => {
            setSbuttonLoading(false);
            if (data.status === 'success') {
              Swal.fire({
                title: 'Success!',
                text: `You have successfully signed up! Please check your email for a verification link.`,
                icon: 'success',
                confirmButtonText: 'OK',
              }).then(() => {
                window.location.href = '/auth/login';
              });
            } else if (data.status === 'error') {
              Swal.fire({
                title: 'Error!',
                text: `Signup was not successful! Error: ${data.message}`,
                icon: 'error',
                confirmButtonText: 'OK',
              });
            } else {
              Swal.fire({
                title: 'Error!',
                text: `Signup was not successful! Please contact your administrator.`,
                icon: 'error',
                confirmButtonText: 'OK',
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  return (
    <Default
      maincard={
        <div className="mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-start lg:justify-start">
          {/* Sign up section */}
          <div className="mt-[3vh] w-full max-w-full flex-col md:pl-4 lg:pl-0 xl:max-w-[420px]">
            <form
              onSubmit={handleSubmit}
              className="w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]"
            >
              <h3 className="text-4xl font-bold text-navy-700 dark:text-white">
                Sign Up
              </h3>
              <p className="ml-1 mt-[10px] text-base text-gray-600">
                Complete the information to register.
              </p>
              {/* user info */}
              <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
                Personal Information
              </h4>
              <div className="mb-3 flex w-full items-center justify-center gap-4">
                <div className="w-1/2">
                  <InputField
                    field_name="firstname"
                    variant="auth"
                    extra="mb-3"
                    label="First Name*"
                    placeholder="John"
                    id="firstname"
                    type="text"
                    required="true"
                  />
                </div>

                <div className="w-1/2">
                  <InputField
                    field_name="lastname"
                    variant="auth"
                    extra="mb-3"
                    label="Last Name*"
                    placeholder="Doe"
                    id="lastname"
                    type="text"
                    required="true"
                  />
                </div>
              </div>
              <InputField
                field_name="email"
                variant="auth"
                extra="mb-3"
                label="Email*"
                placeholder="example@gmail.com"
                id="email"
                type="email"
                required="true"
              />
              <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
                Account Security
              </h4>
              <div className="grid grid-cols-2 gap-3">
                {/* Password */}
                <InputField
                  field_name="password"
                  variant="auth"
                  extra="mb-3"
                  label="Password*"
                  placeholder="Min 8 characters"
                  id="password"
                  type="password"
                  minlength="8"
                  required="true"
                />
                <InputField
                  field_name="confirm_password"
                  variant="auth"
                  extra="mb-3"
                  label="Confirm Password*"
                  placeholder="Min 8 characters"
                  id="confirm_password"
                  type="password"
                  minlength="8"
                  required="true"
                />
              </div>
              {/* Checkbox */}
              <div className="mt-4 flex items-center justify-between px-2">
                <div className="mb-3 flex">
                  <Checkbox
                    id="terms"
                    type="checkbox"
                    field_name="terms"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <label
                    htmlFor="terms"
                    className="ml-2 text-sm text-navy-700 hover:cursor-pointer dark:text-white"
                  >
                    By creating an account means you agree to the Terms and
                    Conditions, and our Privacy Policy
                  </label>
                </div>
              </div>

              {/* button */}

              {sbuttonloading ? (
                <button
                  className="w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                  disabled
                >
                  <Spinner
                    size="xl"
                    thickness="2px"
                    style={{ height: 14, width: 14 }}
                  />
                </button>
              ) : (
                <button className="w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                  Create My Account
                </button>
              )}
            </form>
            <div className="mt-3">
              <span className="text-sm font-medium text-navy-700 dark:text-gray-500">
                Already have an account?
              </span>
              <a
                href="/auth/login"
                className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
              >
                Sign In
              </a>
            </div>
          </div>
        </div>
      }
    />
  );
}

export default Signup;
