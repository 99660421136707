import React from 'react';
import { useAuth } from 'contexts/AuthContext';
import { FaBell } from 'react-icons/fa';
import LineAreaChart from 'components/charts/LineAreaChart';
import { lineChartData, lineChartOptions } from 'variables/dashchart';
import Card from 'components/card';
import Table from './components/Table';
import tableDataUserReports from 'variables/tableDataUserReports';

const Dashboard = () => {
  useAuth();
  return (
    <div className="mt-3 flex h-full w-full flex-col gap-[20px] rounded-md xl:flex-row">
      <div className="h-full w-full rounded-md">
        <div className="col-span-9 h-full w-full rounded-t-2xl xl:col-span-6">
          <div className="col-span-6 h-full w-full rounded-md 3xl:col-span-4">
            <div className="mb-5">
              <Card extra={'h-[381px] p-6'}>
                <div className="flex items-center gap-3">
                  <div className="flex h-14 w-14 items-center justify-center rounded-full bg-lightPrimary text-4xl text-brand-500 dark:!bg-navy-700 dark:text-white">
                    <FaBell className="y-6 w-6" />
                  </div>
                  <div>
                    <h5 className="text-sm font-medium leading-5 text-gray-500 dark:text-gray-400">
                      Notifications in the last 6 months
                    </h5>
                    <p className="mt-1 text-2xl font-bold leading-6 text-navy-900 dark:text-white">
                      1028
                    </p>
                  </div>
                </div>

                {/* Charts */}
                <div className="flex h-full w-full flex-row sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden">
                  <div className="h-full w-full">
                    <LineAreaChart
                      chartData={lineChartData}
                      chartOptions={lineChartOptions}
                    />
                  </div>
                </div>
              </Card>
            </div>
            <div className="h-full w-full rounded-[14px]  ">
              <Table tableData={tableDataUserReports} />
            </div>
          </div>
        </div>
        <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-1"></div>
      </div>
    </div>
  );
};

export default Dashboard;
