import api from '../../../../api';
import Swal from 'sweetalert2';

const TwoFactorActionButtons = (props) => {
  const { id, value, name, py, border, actionName, action } = props;
  const enable_two_factor = (e) => {
    e.preventDefault();
    api
      .post('/2fa/enable', { id })
      .then(({ data }) => {
        if (data.status === 'success') {
          Swal.fire({
            title: 'Two Factor Enabled!',
            text: 'Please scan the QR Code with your Authentication App (Google Authenticator etc.).',
            imageUrl: data.url,
            imageWidth: 300,
            imageHeight: 300,
            imageAlt: '2FA QR Code',
          }).then(() => {
            window.location.reload();
          });
        } else if (data.status === 'error') {
          Swal.fire({
            title: 'Error!',
            text: `Error enabling 2FA! Error: ${data.message}`,
            icon: 'error',
            confirmButtonText: 'OK',
          });
        } else {
          Swal.fire({
            title: 'Error!',
            text: `Error enabling 2FA!`,
            icon: 'error',
            confirmButtonText: 'OK',
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const disable_two_factor = (e) => {
    e.preventDefault();
    api
      .post('/2fa/disable', { id })
      .then(({ data }) => {
        if (data.status === 'success') {
          Swal.fire({
            title: 'Success!',
            text: `Two Factor Authentication have been disabled!`,
            icon: 'success',
            confirmButtonText: 'OK',
          }).then(() => {
            window.location.reload();
          });
        } else if (data.status === 'error') {
          Swal.fire({
            title: 'Error!',
            text: `Error disabling 2FA! Error: ${data.message}`,
            icon: 'error',
            confirmButtonText: 'OK',
          });
        } else {
          Swal.fire({
            title: 'Error!',
            text: `Error disabling 2FA!`,
            icon: 'error',
            confirmButtonText: 'OK',
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div
      className={`flex flex-col items-center justify-between dark:!border-white/10 md:flex-row ${py} ${border} `}
    >
      <p className="text-base font-bold text-navy-700 dark:text-white md:mb-0">
        {' '}
        {name}{' '}
      </p>
      <div className="flex flex-col items-center justify-center md:flex-row">
        <p className="mb-3 text-base font-medium text-gray-600 md:!mb-0 md:mr-7">
          {' '}
          {value}{' '}
        </p>
        {action === 'enable' ? (
          <form onSubmit={enable_two_factor}>
            <button
              type="submit"
              className="linear flex items-center justify-center rounded-md border px-5 py-1.5 text-base font-medium text-navy-700 transition duration-200 hover:cursor-pointer hover:bg-lightPrimary active:bg-gray-200 dark:!border-none dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            >
              {actionName}
            </button>
          </form>
        ) : (
          <form onSubmit={disable_two_factor}>
            <button
              type="submit"
              className="linear flex items-center justify-center rounded-md border px-5 py-1.5 text-base font-medium text-navy-700 transition duration-200 hover:cursor-pointer hover:bg-lightPrimary active:bg-gray-200 dark:!border-none dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            >
              {actionName}
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default TwoFactorActionButtons;
